import { inject, Injectable } from '@angular/core';

import { AuthStatus } from '../../modules/auth/models/AuthStatus';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchDevices,
  forgetDevice,
  rememberDevice,
  signIn,
  signOut,
  updatePassword,
} from 'aws-amplify/auth';
import { environment } from '../../../environments/environment';
import { GlobalErrorHandlerService } from './global-error-handler.service';

interface TotpSetupDetails {
  getSetupUri: (issuer: string) => { href: string };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http = inject(HttpClient);
  private router = inject(Router);
  private nextStepEnabled = false;
  private errorService = inject(GlobalErrorHandlerService);
  getNextStepEnabled() {
    return this.nextStepEnabled;
  }

  async nextStepsController(
    nextStep: AuthStatus,
    totpSetupDetails?: TotpSetupDetails
  ) {
    this.nextStepEnabled = true;
    const routes: Partial<Record<AuthStatus, string>> = {
      [AuthStatus.CONFIRM_SIGN_IN_WITH_TOTP_CODE]: '/auth/mfa-verification',
      [AuthStatus.CONFIRM_SIGN_IN_WITH_EMAIL_CODE]: '/auth/mfa-verification',
      [AuthStatus.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED]:
        '/auth/set-password',
      [AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP]: '/auth/mfa-setup',
      [AuthStatus.DONE]: '/dashboard',
    };

    const route = routes[nextStep];
    if (route) {
      if (
        nextStep === AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP &&
        !totpSetupDetails
      ) {
        throw new Error('totpSetupDetails is required for TOTP setup');
      }
      let routeState = {};
      if (
        nextStep === AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP &&
        totpSetupDetails &&
        typeof totpSetupDetails.getSetupUri === 'function'
      ) {
        const { href } = totpSetupDetails.getSetupUri('Supra');
        routeState = {
          state: {
            totpSetupUri: href,
          },
        };
      }
      await this.router.navigate([route], routeState);
    } else {
      console.error('Unhandled authentication step:', nextStep);
    }
  }

  async signIn(email: string, password: string): Promise<void> {
    const response = await signIn({
      username: email,
      password,
    });

    const { nextStep } = response;
    if (
      nextStep.signInStep === AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP &&
      'totpSetupDetails' in nextStep
    ) {
      await this.nextStepsController(
        nextStep.signInStep as AuthStatus,
        nextStep.totpSetupDetails as unknown as TotpSetupDetails
      );
    } else {
      await this.nextStepsController(nextStep.signInStep as AuthStatus);
    }
  }

  async completeNewPasswordChallenge(newPassword: string): Promise<void> {
    const { nextStep } = await confirmSignIn({
      challengeResponse: newPassword,
    });

    if (
      nextStep.signInStep === AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP &&
      'totpSetupDetails' in nextStep
    ) {
      await this.nextStepsController(
        nextStep.signInStep as AuthStatus,
        nextStep.totpSetupDetails as unknown as TotpSetupDetails
      );
    } else {
      await this.nextStepsController(nextStep.signInStep as AuthStatus);
    }
  }

  async verifyMFA(code: string): Promise<void> {
    const { nextStep } = await confirmSignIn({
      challengeResponse: code,
    });

    await this.nextStepsController(nextStep.signInStep as AuthStatus);
  }

  async signOut() {
    await signOut();
    await this.router.navigate(['/auth/login']);
  }

  async resetPassword(email: string) {
    return this.http.post<string>(
      `${environment.apiGatewayUrl}reset-password`,
      { email }
    );
  }

  confirmResetPassword(
    newPassword: string,
    confirmationCode: string,
    username: string
  ) {
    return confirmResetPassword({
      username,
      confirmationCode,
      newPassword,
    });
  }

  async getAuthToken(): Promise<string | null> {
    const authSession = await fetchAuthSession();
    if (authSession.tokens && authSession.tokens.accessToken) {
      return authSession.tokens.accessToken.toString();
    } else {
      return null;
    }
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    return updatePassword({
      newPassword,
      oldPassword,
    });
  }

  async checkValidDevices(): Promise<void> {
    try {
      const devices = await fetchDevices();

      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      const devicesToRemove = devices.filter(device => {
        const deviceDate = device.createDate
          ? new Date(device.createDate)
          : new Date();
        return deviceDate < twoWeeksAgo;
      });

      await Promise.all(
        devicesToRemove.map(device => this.forgetDeviceById(device.id))
      );
    } catch (error) {
      this.errorService.logError(error);
    }
  }
  async forgetDeviceById(deviceToRemove: string) {
    try {
      await forgetDevice({
        device: {
          id: deviceToRemove,
        },
      });
    } catch (error) {
      this.errorService.logError(error);
    }
  }
  async rememberCurrentDevice() {
    try {
      await rememberDevice();
    } catch (error) {
      this.errorService.logError(error);
    }
  }
}
